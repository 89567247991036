
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Fade animation for the images */
.fade {
  animation: fadeEffect 0.8s ease-in-out;
}

@keyframes fadeEffect {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Flip animation for the images */
.flip {
  animation: flipEffect 0.8s ease-in-out;
}

@keyframes flipEffect {
  0% {
    transform: rotateX(90deg);
    opacity: 1;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

/* Slide animation for the images */
.slide {
  animation: slideEffect 0.8s ease-in-out;
}

@keyframes slideEffect {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Single-Letter {
  width: auto;
  margin-right: 12pt;
}

.type-writer {
  display: flex;
  font-size: 36px;
  color: white;
  margin-bottom: 15px;
}

@media (max-width: 800px) {
  .type-writer {
    font-size: 50px;
  }

  .Single-Letter {
    margin-right: 2pt;
  }
}

.type-writer :nth-child(1) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.25s;
}

.type-writer :nth-child(2) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.35s;
}

.type-writer :nth-child(3) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.45s;
}

.type-writer :nth-child(4) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.55s;
}

.type-writer :nth-child(5) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.65s;
}

.type-writer :nth-child(6) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.75s;
}

.type-writer :nth-child(7) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.85s;
}

.type-writer :nth-child(8) {
  opacity: 0;
  animation: type 0.5s forwards;
  animation-delay: 2.95s;
}


#draw1 {
  stroke-dasharray: 4572;
  stroke-dashoffset: 4572;
  animation: draw 3s forwards;
  animation-delay: 1s;
}

#draw2 {
  stroke-dasharray: 4572;
  stroke-dashoffset: 4572;
  animation: draw 6s forwards;
  animation-delay: 1.5s;
}

#draw3 {
  stroke-dasharray: 4572;
  stroke-dashoffset: 4572;
  animation: draw 1s forwards;
  animation-delay: 3.5s;
}

#draw4 {
  stroke-dasharray: 1072;
  stroke-dashoffset: 1072;
  animation: draw 10s forwards;
}

@keyframes draw {
  0% {
    stroke: white;
  }

  15% {
    stroke: white;
  }

  75% {
    stroke-dashoffset: 1;
  }

  100% {
    stroke-dashoffset: 1;
    stroke: #FC7335;
    /* Replaced $Gold with 'gold' for standard CSS */
  }
}

@-webkit-keyframes draw {
  0% {
    stroke: white;
  }

  15% {
    stroke: white;
  }

  75% {
    stroke-dashoffset: 1;
  }

  100% {
    stroke-dashoffset: 1;
    stroke: #FC7335;
    /* Replaced $Gold with 'gold' for standard CSS */
  }
}

@media (max-width: 800px) {
  @keyframes draw {
    0% {
      stroke: white;
    }

    15% {
      stroke: white;
    }

    75% {
      stroke-dashoffset: 1;
    }

    100% {
      stroke-dashoffset: 1;
      stroke: white;
      /* Replaced $Gold with 'gold' for standard CSS */
    }
  }

  @-webkit-keyframes draw {
    0% {
      stroke: white;
    }

    15% {
      stroke: white;
    }

    75% {
      stroke-dashoffset: 1;
    }

    100% {
      stroke-dashoffset: 1;
      stroke: white;
      /* Replaced $Gold with 'gold' for standard CSS */
    }
  }
}

@keyframes type {
  from {
    transform: scale(3);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.home-banner {
  min-width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  min-height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 300px;
  }
}